import React from 'react';

import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import type { PageProps } from '@/components/layout';

import getCurrentUrl from '@/utils/getCurrentUrl';
import { PageHeader, ShareModal, useShare } from '@/components/ui';

import {
  ConversationStartersContentDesktop,
  ConversationStartersContentMobile,
} from './components';

export type ConversationStartersProps = {
  pageProps: PageProps;
  conversationStarters: {
    metaTitle?: string;
    metaDescription?: string;
    shareImage?: string;
    heading: string;
    description: string;
    questions: string[];
    slug: string;
  }[];
  selectedIndex: number | null;
  onChangeIndex: (index: number | null) => void;
};

const ConversationStarters: React.FC<ConversationStartersProps> = React.memo(
  function ConversationStarters({
    pageProps,
    conversationStarters,
    selectedIndex,
    onChangeIndex,
  }) {
    // Get the title and URL of the page for users to share
    const title = 'Conversations';
    const description =
      'Starting a conversation can be difficult. The topics and questions below may give you some ideas to begin with';
    const textToShare = [title, getCurrentUrl()].join('\n');
    const { onShare, showShareModal, onCloseShareModal } = useShare(
      textToShare
    );

    return (
      <Page {...pageProps}>
        <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
          <FlexGridItem>
            <PageHeader
              title={title}
              description={description}
              mobileImageUrl={require('@/images/conversation-page-header-small.svg')}
              desktopImageUrl={require('@/images/conversation-page-header-large.svg')}
              backgroundColor="lately.core"
              color="lately.text"
              desktopBackgroundProps={{ backgroundSize: '80%' }}
            />
          </FlexGridItem>
        </FlexGridRow>
        {/* Mobile layout with accordions */}
        <ConversationStartersContentMobile
          conversationStarters={conversationStarters}
          selectedIndex={selectedIndex}
          onChangeIndex={onChangeIndex}
          onShare={onShare}
        />
        {/* Tablet/desktop layout with fixed sidebar */}
        <ConversationStartersContentDesktop
          conversationStarters={conversationStarters}
          selectedIndex={selectedIndex}
          onChangeIndex={onChangeIndex}
          onShare={onShare}
        />
        <ShareModal
          isOpen={showShareModal}
          onClose={onCloseShareModal}
          textToShare={textToShare}
        />
      </Page>
    );
  }
);

export default ConversationStarters;
