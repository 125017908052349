import React, { useState } from 'react';
import { Flex, Spinner, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import {
  CustomPagination,
  Disclaimer,
  PageHeader,
  SearchResultCard,
  SearchResultTypes,
  ShareModal,
  useShare,
} from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import { ActionsSection } from '@/components/sections';
import { useSavedContent } from '@/components/providers';
import { SavedContentType } from '@/utils/saved-content';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import type { PageProps } from '@/components/layout';

const itemsPerPage = 10;

export type SavedItem = {
  type: SavedContentType;
  id: string;
  title: string;
  description: string;
  url: string;
  saved: number;
  email?: string;
  phone?: string;
  locations?: string[];
};

export type SavedProps = {
  pageProps: PageProps;
  title: string;
  subtitle: string;
  description: string;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  explanation: string;
  illustrationMobileHeader: string;
  illustrationDesktopHeader: string;
  disclaimer: {
    title: string;
    description: string;
  };
  loading: boolean;
  savedItems: SavedItem[];
} & FlexProps;

const mapSavedTypeToSearchType = (
  savedType: SavedContentType
): SearchResultTypes => {
  return {
    [SavedContentType.Service]: SearchResultTypes.Services,
    [SavedContentType.Faq]: SearchResultTypes.Questions,
  }[savedType];
};

const Saved: React.FC<SavedProps> = React.memo(function Saved({
  pageProps,
  title,
  subtitle,
  description,
  explanation,
  illustrationMobileHeader,
  illustrationDesktopHeader,
  disclaimer,
  loading,
  savedItems,
  children,
  ...rest
}) {
  const savedContent = useSavedContent();

  const pageCount = Math.ceil(savedItems.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate which items should be displayed based on the current page
  const itemsToSkip = (currentPage - 1) * itemsPerPage;
  const itemsOnPage = savedItems.slice(itemsToSkip, itemsToSkip + itemsPerPage);

  // Generate a list of saved items with the title and the URL for the user to share
  const siteUrl = process.env.SITE_URL ?? '';
  const itemLinks = savedItems.map((i) => `${i.title}\n${siteUrl}${i.url}`);
  const textToShare = itemLinks.join('\n\n');
  const { onShare, showShareModal, onCloseShareModal } = useShare(textToShare);

  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <PageHeader
            title={title}
            description={subtitle}
            mobileImageUrl={illustrationMobileHeader}
            desktopImageUrl={illustrationDesktopHeader}
            desktopBackgroundProps={{ backgroundPosition: 'center' }}
          />
        </FlexGridItem>
      </FlexGridRow>
      <Stack
        paddingBottom={{ base: 24, lg: 32 }}
        paddingTop={{ base: 10, lg: 16 }}
        spacing={{ base: 24, lg: 32 }}
        width="100%"
      >
        <FlexGridRow>
          <FlexGridItem colWidth={{ base: 4, md: 3, lg: 7 }}>
            <Stack alignItems="center" spacing={10} width="100%">
              <Text {...textStyles.body} opacity={0.8} width="100%">
                {loading || savedItems.length > 0 ? description : explanation}
              </Text>
              {loading && (
                <Spinner
                  color="lately.highlights03"
                  emptyColor="lately.black20"
                  size="xl"
                  thickness="4px"
                />
              )}
              {savedItems.length > 0 && (
                <Stack
                  alignItems="center"
                  spacing={{ base: 8, lg: 12 }}
                  width="100%"
                >
                  <Stack spacing={0} width="100%">
                    {itemsOnPage.map((item) => {
                      const [
                        savedState,
                        onToggleSave,
                      ] = savedContent.getItemState(item.id, item.type);
                      return (
                        <SearchResultCard
                          key={item.url}
                          resultType={mapSavedTypeToSearchType(item.type)}
                          isNewlyAdded={false}
                          name={item.title}
                          description={item.description}
                          url={item.url}
                          email={item.email}
                          phone={item.phone}
                          locations={item.locations}
                          savedState={savedState}
                          onToggleSave={onToggleSave}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </FlexGridItem>
          <FlexGridItem
            colOffset={{ base: 0, lg: 1 }}
            colWidth={{ base: 4, md: 3, lg: 4 }}
            marginTop={{ base: 24, lg: 0 }}
          >
            <Disclaimer
              title={disclaimer.title}
              description={disclaimer.description}
              width="100%"
            />
          </FlexGridItem>
        </FlexGridRow>
        {pageCount > 1 && (
          <Flex justifyContent="center">
            <CustomPagination
              currentRefinement={currentPage}
              nbPages={pageCount}
              refine={setCurrentPage}
            />
          </Flex>
        )}
        {savedItems.length > 0 && (
          <ActionsSection
            actions={[
              {
                ...ActionsSection.Actions.Print,
                children: 'Print this list',
              },
              {
                ...ActionsSection.Actions.Share,
                children: 'Share',
                onClick: onShare,
              },
            ]}
            width="100%"
          />
        )}
      </Stack>
      <ShareModal
        isOpen={showShareModal}
        onClose={onCloseShareModal}
        title="Share your list"
        description={
          "To share this list, copy and paste the links below.\nSelect the 'Copy to clipboard' option, then paste the links in an email, a message or social media post."
        }
        textToShare={textToShare}
      />
    </Page>
  );
});

export default Saved;
