import React from 'react';
import { Stack } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import getCurrentUrl from '@/utils/getCurrentUrl';
import { SectionsProps } from '@/utils/types';
import { PageHeader, ShareModal, useShare } from '@/components/ui';
import { ActionsSection } from '@/components/sections';
import {
  FlexGridItem,
  FlexGridRow,
  Page,
  DynamicSection,
} from '@/components/layout';
import type { PageProps } from '@/components/layout';

export type StandardContentProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  title: string;
  subtitle?: string;
  sections: SectionsProps;
} & FlexProps;

const StandardContent: React.FC<StandardContentProps> = React.memo(
  function StandardContent({
    pageProps,
    title,
    subtitle = '',
    sections,
    ...rest
  }) {
    // Get the title and URL of the page for users to share
    const textToShare = [title, getCurrentUrl()].join('\n');
    const { onShare, showShareModal, onCloseShareModal } = useShare(
      textToShare
    );

    return (
      <Page {...pageProps} {...rest}>
        <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
          <FlexGridItem>
            <PageHeader title={title} description={subtitle} />
          </FlexGridItem>
        </FlexGridRow>
        <Stack paddingY={16} spacing={{ base: 16, lg: 20 }}>
          <DynamicSection sections={sections} />
          <ActionsSection
            actions={[
              ActionsSection.Actions.Print,
              {
                ...ActionsSection.Actions.Share,
                onClick: onShare,
              },
            ]}
            marginTop={{ base: 8, lg: 12 }}
          />
        </Stack>
        <ShareModal
          isOpen={showShareModal}
          onClose={onCloseShareModal}
          textToShare={textToShare}
        />
      </Page>
    );
  }
);

export default StandardContent;
