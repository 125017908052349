/** @jsx jsx */
import React from 'react';
import type { ReactHTML } from 'react';
import { Box, Heading, Icon, Stack } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import Markdown from 'markdown-to-jsx';
import { rem } from 'polished';
import { css, jsx } from '@emotion/core';

import getCurrentUrl from '@/utils/getCurrentUrl';
import { generateServiceCategoryRefinementsAsUrl } from '@/utils/search';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import {
  ConversationCard,
  HubHeader,
  MarkdownComponent,
  ShareModal,
  useShare,
} from '@/components/ui';
import {
  ActionsSection,
  FaqsSection,
  FaqsSectionProps,
  RelatedCategoriesSection,
  CategoriesProps,
  RelatedServicesSection,
  RelatedServicesSectionProps,
} from '@/components/sections';
import type { PageProps } from '@/components/layout';
import type { ConversationCardProps } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

export type HubProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  title: string;
  illustrationDesktopHeader?: string;
  illustrationMobileHeader?: string;
  textBlock: string;
  conversationStarters: ConversationCardProps[];
  categories: CategoriesProps;
  relatedServices: Omit<
    RelatedServicesSectionProps,
    'allRelatedServicesSearchUrl'
  > & {
    relatedServicesCategoryTags: string[];
  };
  faqs: FaqsSectionProps;
} & FlexProps;

const Hub: React.FC<HubProps> = React.memo(function Hub({
  pageProps,
  title,
  illustrationDesktopHeader,
  illustrationMobileHeader,
  textBlock,
  conversationStarters,
  categories,
  relatedServices,
  faqs,
  ...rest
}) {
  // Get the title and URL of the page for users to share
  const textToShare = [title, getCurrentUrl()].join('\n');
  const { onShare, showShareModal, onCloseShareModal } = useShare(textToShare);

  const allRelatedServicesSearchUrl = generateServiceCategoryRefinementsAsUrl(
    relatedServices.relatedServicesCategoryTags
  );

  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <HubHeader
            title={title}
            illustrationDesktopHeader={illustrationDesktopHeader}
            illustrationMobileHeader={illustrationMobileHeader}
          />
        </FlexGridItem>
      </FlexGridRow>
      <Stack spacing={{ base: 16, lg: 20 }}>
        <FlexGridRow marginTop={{ base: rem('44px'), md: 10, lg: 16 }}>
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 6 }}
            paddingRight={{ base: 0, md: 10, lg: 0 }}
            marginBottom={{ base: 16, md: 0 }}
            css={css`
              *:last-child {
                margin-bottom: 0;
              }
            `}
          >
            <Box
              maxWidth={{
                base: rem('515px'),
                md: rem('468px'),
                lg: rem('570px'),
              }}
            >
              {textBlock && (
                <Markdown
                  options={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    createElement(type, props: any, text) {
                      const { key } = props;
                      return (
                        <MarkdownComponent
                          type={type as keyof ReactHTML}
                          props={props}
                          key={key}
                          headingProps={{
                            marginBottom: { base: rem('24px'), lg: 10 },
                          }}
                        >
                          {text}
                        </MarkdownComponent>
                      );
                    },
                  }}
                >
                  {textBlock}
                </Markdown>
              )}
            </Box>
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 5 }}
            colOffset={{ base: 0, md: 0, lg: 1 }}
          >
            <Box
              width="100%"
              maxWidth={{
                base: rem('450px'),
                md: rem('686px'),
                lg: rem('461px'),
              }}
            >
              {conversationStarters[0] && (
                <ConversationCard {...conversationStarters[0]} />
              )}
            </Box>
          </FlexGridItem>
        </FlexGridRow>
        <FlexGridRow>
          <FlexGridItem
            colWidth={{ base: 4, md: 4, lg: 12 }}
            marginBottom={{ base: 6, md: 4, lg: 6 }}
          >
            {categories.length > 0 && (
              <Heading as="h3" {...textStyles.h3}>
                Related categories
                <Icon
                  name="search"
                  display="inline"
                  marginBottom={1}
                  marginLeft={rem('10px')}
                />
              </Heading>
            )}
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 6 }}
            paddingRight={{ base: 0, md: 10, lg: 0 }}
            marginBottom={{ base: 16, md: 0 }}
          >
            <Box width="100%">
              {categories.length > 0 && (
                <RelatedCategoriesSection categories={categories} />
              )}
            </Box>
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 5 }}
            colOffset={{ base: 0, md: 0, lg: 1 }}
          >
            <Box
              width="100%"
              maxWidth={{
                base: rem('450px'),
                md: rem('686px'),
                lg: rem('461px'),
              }}
            >
              {conversationStarters[1] && (
                <ConversationCard {...conversationStarters[1]} />
              )}
            </Box>
          </FlexGridItem>
        </FlexGridRow>
        {relatedServices.services.length > 0 && (
          <FlexGridRow>
            <FlexGridItem colWidth={{ base: 4, md: 4, lg: 12 }}>
              <RelatedServicesSection
                {...relatedServices}
                allRelatedServicesSearchUrl={allRelatedServicesSearchUrl}
              />
            </FlexGridItem>
          </FlexGridRow>
        )}
        {faqs.faqs.length > 0 && (
          <FlexGridRow width="100%">
            <FlexGridItem colWidth={{ base: 4, md: 4, lg: 12 }}>
              <FaqsSection width="100%" {...faqs} />
            </FlexGridItem>
          </FlexGridRow>
        )}
      </Stack>
      <ActionsSection
        actions={[
          ActionsSection.Actions.Print,
          {
            ...ActionsSection.Actions.Share,
            children: 'Share',
            onClick: onShare,
          },
        ]}
        marginTop={{ base: rem('56px'), md: 24, lg: 32 }}
        marginBottom={{ base: 24, lg: 32 }}
        width="100%"
      />
      <ShareModal
        isOpen={showShareModal}
        onClose={onCloseShareModal}
        textToShare={textToShare}
      />
    </Page>
  );
});

export default Hub;
