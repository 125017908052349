import React from 'react';
import { Stack } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import {
  HubListSection,
  PromoLinkSection,
  FaqsSection,
  BlogPostsSection,
} from '@/components/sections';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import {
  HomeHeader,
  HomeHeaderCampaign,
  PopularCategories,
} from '@/components/ui';
import type { BlogTileProps, HomeHeaderProps } from '@/components/ui';
import type { PageProps } from '@/components/layout';
import type { FaqsSectionProps, HubsListProps } from '@/components/sections';

export type HomeProps = {
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  pageProps: PageProps;
  headerProps?: HomeHeaderProps;
  hubsListHeading: string;
  hubs: HubsListProps;
  blogPosts?: {
    heading?: string;
    button: {
      href?: string;
      label?: string;
    };
    posts: BlogTileProps[];
  };
  faqs: FaqsSectionProps;
  promo?: {
    title: string;
    description: string;
    link: {
      text: string;
      url: string;
    };
  };
} & FlexProps;

const Home: React.FC<HomeProps> = React.memo(function Home({
  pageProps,
  headerProps,
  hubsListHeading,
  hubs,
  faqs,
  blogPosts,
  promo,
  children,
  ...rest
}) {
  return (
    <Page {...pageProps} {...rest}>
      {headerProps && !headerProps.isCampaign && (
        <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
          <FlexGridItem>
            <HomeHeader {...headerProps} />
          </FlexGridItem>
        </FlexGridRow>
      )}
      {headerProps && headerProps.isCampaign && (
        <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
          <FlexGridItem>
            <HomeHeaderCampaign {...headerProps} />
          </FlexGridItem>
        </FlexGridRow>
      )}
      <Stack
        paddingBottom={{ base: 24, lg: 32 }}
        paddingTop={{ base: 10, lg: 16 }}
        spacing={{ base: 8, md: 16 }}
      >
        <Stack spacing={{ base: 10, md: 16 }}>
          <Stack spacing={{ base: 10, lg: 16 }}>
            <Stack spacing={{ base: 16, lg: 32 }}>
              <HubListSection heading={hubsListHeading} hubs={hubs} />
            </Stack>
            <Stack spacing={{ base: 16, lg: 32 }}>
              <FlexGridRow>
                <FlexGridItem direction="column">
                  <PopularCategories
                    numberOfCategoriesToShow={9}
                    {...pageProps.popularCategoriesProps}
                  />
                </FlexGridItem>
              </FlexGridRow>
              <FaqsSection {...faqs} />
              {promo !== undefined && <PromoLinkSection data={promo} />}
              {blogPosts && (
                <BlogPostsSection
                  heading={blogPosts.heading}
                  blogPosts={blogPosts.posts}
                  button={blogPosts.button}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Page>
  );
});

export default Home;
