import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import chunk from 'lodash.chunk';
import { useRouter } from 'next/router';

import {
  Button,
  PageHeader,
  BlogTile,
  CustomPagination,
} from '@/components/ui';

import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import type { PageProps } from '@/components/layout';
import type { BlogTileProps } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';

export type BlogListProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  title: string;
  description: string;
  illustrationMobileHeader?: string;
  illustrationDesktopHeader?: string;
  blogPosts: BlogTileProps[];
  blogCategories: {
    name: string;
  }[];
} & FlexProps;

const BlogList: React.FC<BlogListProps> = React.memo(function BlogList({
  pageProps,
  title,
  description,
  illustrationMobileHeader,
  illustrationDesktopHeader,
  blogPosts,
  blogCategories,
  children,
  ...rest
}) {
  const router = useRouter();
  const pageLength = 6;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(blogPosts.length / pageLength)
  );
  const [blogList, setBlogList] = useState(chunk(blogPosts, pageLength));
  const [categoriesList, setCategoriesList] = useState<
    {
      isSelected: boolean;
      name: string;
    }[]
  >([]);

  const resetCategoryFilters = () => {
    return blogCategories.map((category) => {
      return {
        name: category.name,
        isSelected: false,
      };
    });
  };

  const clearFilters = async () => {
    setBlogList(chunk(blogPosts, pageLength));
    setTotalPages(Math.ceil(blogPosts.length / pageLength));
    setCategoriesList(resetCategoryFilters());
  };

  const updateBlogsList = (
    updatedCategoriesList: {
      name: string;
      isSelected: boolean;
    }[]
  ) => {
    const filteredBlogList: BlogTileProps[] = [];
    const selectedCategories: string[] = [];

    updatedCategoriesList.forEach((category) => {
      if (category.isSelected) {
        selectedCategories.push(category.name);
      }
    });

    if (selectedCategories.length === 0) {
      clearFilters();
      return;
    }

    blogPosts.forEach((post) => {
      let isAddedToFilteredList = false;
      post.categories.forEach((cat) => {
        if (selectedCategories.includes(cat) && !isAddedToFilteredList) {
          filteredBlogList.push(post);
          isAddedToFilteredList = true;
        }
      });
    });
    setBlogList(chunk(filteredBlogList, pageLength));
    setTotalPages(Math.ceil(filteredBlogList.length / pageLength));
  };

  const updateFilterQuery = (categories: string) => {
    if (!categories) {
      router.replace({
        query: {},
      });
      return;
    }
    router.replace({
      query: { ...router.query, filter: categories },
    });
  };

  const updateCategories = (
    categoryUpdated: {
      name: string;
      isSelected: boolean;
    },
    limitOneCategory?: boolean
  ) => {
    const filtersQuery: string[] = [];
    let updatedCategoriesList;
    if (limitOneCategory) {
      filtersQuery.push(categoryUpdated.name);
      updatedCategoriesList =
        categoriesList &&
        categoriesList.map((categoryItem) => {
          if (categoryItem.name === categoryUpdated.name) {
            return {
              name: categoryUpdated.name,
              isSelected: true,
            };
          }
          return { name: categoryItem.name, isSelected: false };
        });
      setCategoriesList(updatedCategoriesList);
    } else {
      updatedCategoriesList =
        categoriesList &&
        categoriesList.map((categoryItem) => {
          if (categoryItem.name === categoryUpdated.name) {
            return {
              name: categoryUpdated.name,
              isSelected: !categoryUpdated.isSelected,
            };
          }
          return categoryItem;
        });
      updatedCategoriesList.forEach((category) => {
        if (category.isSelected) {
          filtersQuery.push(category.name);
        }
      });
      setCategoriesList(updatedCategoriesList);
    }
    updateBlogsList(updatedCategoriesList);
    updateFilterQuery(filtersQuery.join('&'));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const filters = urlParams.get('filter')?.split('&');

    const categoriesSelectOnLoad =
      blogCategories &&
      blogCategories.map((category) => {
        if (filters && filters.includes(category.name)) {
          return {
            name: category.name,
            isSelected: true,
          };
        }
        return {
          name: category.name,
          isSelected: false,
        };
      });

    setCategoriesList(categoriesSelectOnLoad);
    updateBlogsList(categoriesSelectOnLoad);
  }, []);

  useEffect(() => {
    const pageNumber = Math.min(
      totalPages,
      Math.max(1, Number(router.query?.page))
    );

    if (currentPage || !router.isReady) {
      return;
    }

    if (!router.query?.page) {
      setCurrentPage(1);
      return;
    }

    setCurrentPage(pageNumber);
    router.replace({
      query: { ...router.query, page: pageNumber },
    });
  });

  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <PageHeader
            title={title}
            description={description}
            backgroundColor="lately.core"
            mobileImageUrl={illustrationMobileHeader}
            desktopImageUrl={illustrationDesktopHeader}
            color="lately.text"
            desktopBackgroundProps={{ backgroundSize: '80%' }}
          />
        </FlexGridItem>
      </FlexGridRow>

      {blogCategories.length > 0 ? (
        <FlexGridRow height="auto">
          <FlexGridItem paddingTop={4} paddingBottom={10} alignItems="center">
            <Text
              {...textStyles.h5}
              color="lately.highlights03"
              marginRight={8}
              minWidth="140px"
            >
              Filter by:
            </Text>
            <Flex flexWrap="wrap" marginBottom={-2} justifyContent="flex-start">
              {categoriesList.map((category) => (
                <Button
                  key={category.name}
                  variant="secondary"
                  isActive={category.isSelected}
                  aria-selected={category.isSelected ? 'true' : 'false'}
                  marginRight={2}
                  marginBottom={2}
                  onClick={() => {
                    updateCategories(category);
                  }}
                  aria-label={`"${category.name}" filter`}
                >
                  {category.name}
                </Button>
              ))}
              <Button
                variant="feature"
                marginRight={2}
                marginBottom={2}
                onClick={() => {
                  clearFilters();
                }}
              >
                Clear filters
              </Button>
            </Flex>
          </FlexGridItem>
        </FlexGridRow>
      ) : null}

      <Flex flexWrap="wrap" marginBottom={8} marginX={-4}>
        {blogList[currentPage - 1] &&
          blogList[currentPage - 1].map((blog, i) => (
            <BlogTile
              // eslint-disable-next-line react/no-array-index-key
              key={`${blog.title}-${i}`}
              {...blog}
              marginX={4}
              marginY={{ base: 6, md: 10 }}
              filterByCategory={(c) => {
                updateCategories(c, true);
              }}
            />
          ))}
      </Flex>
      <Box marginBottom={20}>
        <CustomPagination
          currentRefinement={currentPage}
          nbPages={totalPages}
          refine={(newPageNumber) => {
            setCurrentPage(newPageNumber);
          }}
        />
      </Box>
    </Page>
  );
});

export default BlogList;
