import React from 'react';
import type { ReactHTML } from 'react';
import NextLink from 'next/link';
import Markdown from 'markdown-to-jsx';
import { rem } from 'polished';
import { Flex, Heading, Icon, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import getCurrentUrl from '@/utils/getCurrentUrl';
import * as textStyles from '@/theme/textStyles';
import {
  MarkdownComponent,
  Button,
  Disclaimer,
  PageHeader,
  ShareModal,
  useShare,
} from '@/components/ui';
import { ActionsSection } from '@/components/sections';
import { useSavedContent } from '@/components/providers';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import { SavedContentState, SavedContentType } from '@/utils/saved-content';
import type { PageProps } from '@/components/layout';

type ConversationStarterLinksProps = {
  conversationStarters: {
    title: string;
    url: string;
  }[];
} & FlexProps;

const ConversationStarterLinks: React.FC<ConversationStarterLinksProps> = React.memo(
  function ConversationStarterLinks({ conversationStarters, ...rest }) {
    return (
      <Flex direction="column" width="100%" {...rest}>
        <Heading {...textStyles.h5}>Conversation starters:</Heading>
        <Stack marginTop={6} spacing={4}>
          {conversationStarters.map((c) => (
            <Stack key={c.url} isInline spacing={rem('10px')}>
              <Icon
                name="conversationStarter"
                height={rem('32px')}
                width={rem('32px')}
              />
              <NextLink href={c.url} passHref>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  {...textStyles.body}
                  textDecoration="underline"
                  _hover={{ textDecoration: 'none' }}
                >
                  {c.title}
                </Link>
              </NextLink>
            </Stack>
          ))}
        </Stack>
      </Flex>
    );
  }
);

export type FaqDetailsProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  id: string;
  title: string;
  description: string;
  illustrationMobileHeader: string;
  illustrationDesktopHeader: string;
  disclaimerTop: {
    title: string;
    description: string;
  };
  disclaimerBottom: {
    title: string;
    description: string;
  };
  conversationStarters: {
    title: string;
    url: string;
  }[];
  resources: {
    title: string;
    description: string;
    url: string;
  }[];
} & FlexProps;

const FaqDetails: React.FC<FaqDetailsProps> = React.memo(function FaqDetails({
  pageProps,
  id,
  title,
  description,
  illustrationMobileHeader,
  illustrationDesktopHeader,
  disclaimerTop,
  disclaimerBottom,
  conversationStarters,
  resources,
  children,
  ...rest
}) {
  // Get the title and URL of the page for users to share
  const textToShare = [title, getCurrentUrl()].join('\n');
  const { onShare, showShareModal, onCloseShareModal } = useShare(textToShare);

  // Get the saved state of the FAQ
  const savedContent = useSavedContent();
  const [savedState, onToggleSave] = savedContent.getItemState(
    id,
    SavedContentType.Faq
  );

  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <PageHeader
            title={title}
            mobileImageUrl={illustrationMobileHeader}
            desktopImageUrl={illustrationDesktopHeader}
            backgroundColor="lately.answers"
            color="lately.text"
            desktopBackgroundProps={{ backgroundSize: '80%' }}
          />
        </FlexGridItem>
      </FlexGridRow>
      <FlexGridRow
        display={{ base: 'flex', lg: 'none' }}
        marginTop={{ base: 10, lg: 16 }}
      >
        <FlexGridItem colWidth={{ base: 4, md: 3, lg: 9 }}>
          <Disclaimer
            title={disclaimerTop.title}
            description={disclaimerTop.description}
          />
        </FlexGridItem>
      </FlexGridRow>
      <FlexGridRow marginTop={{ base: 10, lg: 16 }}>
        <FlexGridItem colWidth={{ base: 4, md: 3, lg: 7 }}>
          <Flex direction="column" width="100%">
            <Markdown
              options={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                createElement(type, props: any, text) {
                  const { key } = props;
                  return (
                    <MarkdownComponent
                      type={type as keyof ReactHTML}
                      props={props}
                      key={key}
                      headingProps={{
                        marginBottom: { base: 2, lg: 4 },
                        marginTop: 10,
                      }}
                    >
                      {text}
                    </MarkdownComponent>
                  );
                },
              }}
            >
              {description}
            </Markdown>
          </Flex>
        </FlexGridItem>
        <FlexGridItem
          display={{ base: 'none', lg: 'flex' }}
          colOffset={1}
          colWidth={4}
        >
          <Stack spacing={20} width="100%">
            <Disclaimer
              title={disclaimerTop.title}
              description={disclaimerTop.description}
            />
            <ConversationStarterLinks
              conversationStarters={conversationStarters}
            />
          </Stack>
        </FlexGridItem>
      </FlexGridRow>
      <FlexGridRow
        display={{ base: 'flex', lg: 'none' }}
        marginTop={{ base: 16 }}
      >
        <FlexGridItem colWidth={{ base: 4, md: 3 }}>
          <ConversationStarterLinks
            conversationStarters={conversationStarters}
          />
        </FlexGridItem>
      </FlexGridRow>
      <FlexGridRow marginTop={{ base: 16, lg: 20 }}>
        <FlexGridItem colWidth={{ base: 4, md: 3, lg: 9 }}>
          <Stack spacing={4} width="100%">
            <Heading as="h2" {...textStyles.h4}>
              More information
            </Heading>
            <Stack spacing={2} width="100%">
              {resources.map((r) => (
                <Button
                  as="a"
                  key={r.url}
                  href={r.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="feature"
                  paddingX={6}
                  paddingY={5}
                  textAlign="left"
                  width="100%"
                >
                  <Stack flexGrow={1} marginRight={6} spacing={2} width="100%">
                    <Text {...textStyles.body} fontWeight="bold">
                      {r.title}
                    </Text>
                    {r.description && (
                      <Text {...textStyles.smallData}>{r.description}</Text>
                    )}
                  </Stack>
                  <Flex alignSelf="flex-start" padding={rem('4px')}>
                    <Icon name="externalLink" width={rem('16px')} />
                  </Flex>
                </Button>
              ))}
            </Stack>
          </Stack>
        </FlexGridItem>
      </FlexGridRow>
      <FlexGridRow marginTop={{ base: 16, lg: 20 }}>
        <FlexGridItem colWidth={{ base: 4, md: 3, lg: 9 }}>
          <Disclaimer
            title={disclaimerBottom.title}
            description={disclaimerBottom.description}
          />
        </FlexGridItem>
      </FlexGridRow>
      <ActionsSection
        actions={[
          ActionsSection.Actions.Print,
          {
            ...ActionsSection.Actions.Save,
            children:
              savedState === SavedContentState.None
                ? 'Save for later'
                : 'Remove',
            isLoading: savedState === SavedContentState.Pending,
            onClick: onToggleSave,
          },
          {
            ...ActionsSection.Actions.Share,
            children: 'Share',
            onClick: onShare,
          },
        ]}
        stackDirection="row"
        marginBottom={{ base: 0, md: 24, lg: 32 }}
        marginTop={{ base: 24, lg: 32 }}
        width="100%"
      >
        <NextLink href="/faq" passHref>
          <Button
            as="a"
            variant="textLink"
            color="lately.highlights03"
            display={{ base: 'none', md: 'flex' }}
            paddingX={6}
          >
            See more answers
          </Button>
        </NextLink>
      </ActionsSection>
      <FlexGridRow
        display={{ base: 'flex', md: 'none' }}
        marginBottom={{ base: 24 }}
        marginTop={4}
      >
        <FlexGridItem>
          <NextLink href="/faq" passHref>
            <Button as="a" variant="textLink" color="lately.highlights03">
              See more answers
            </Button>
          </NextLink>
        </FlexGridItem>
      </FlexGridRow>
      <ShareModal
        isOpen={showShareModal}
        onClose={onCloseShareModal}
        textToShare={textToShare}
      />
    </Page>
  );
});

export default FaqDetails;
