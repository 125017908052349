/** @jsx jsx */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { ReactHTML } from 'react';
import { rem } from 'polished';
import chunk from 'lodash.chunk';
import throttle from 'lodash.throttle';
import Markdown from 'markdown-to-jsx';
import { css, jsx } from '@emotion/core';
import { Flex, Heading, Link, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import gridConfig from '@/theme/grid';
import { spacing } from '@/theme/chakra';
import * as textStyles from '@/theme/textStyles';
import useStickyfill from '@/utils/useStickyfill';
import { Button, MarkdownComponent, PageHeader } from '@/components/ui';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import type { PageProps } from '@/components/layout';

const desktopHeaderHeight = rem('96px');
const alphabetLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const lettersPerRow = 7;

type Word = {
  word: string;
  definition: string;
};

const isFirstWordWithInitialLetter = (index: number, words: Word[]) => {
  if (index === 0) return true;
  const firstLetter = words[index].word[0];
  const firstLetterOfPreviousWord = words[index - 1].word[0];
  return firstLetter.toUpperCase() !== firstLetterOfPreviousWord.toUpperCase();
};

export type GlossaryProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  title: string;
  description: string;
  illustrationMobileHeader: string;
  illustrationDesktopHeader: string;
  words: Word[];
} & FlexProps;

const Glossary: React.FC<GlossaryProps> = React.memo(function Glossary({
  pageProps,
  title,
  description,
  illustrationMobileHeader,
  illustrationDesktopHeader,
  words,
  children,
  ...rest
}) {
  // Sort the word list alphabetically
  const sortedWords = useMemo(() => {
    return words.sort((a, b) => a.word.localeCompare(b.word));
  }, [words]);

  // Find all unique letters that are used at the beginning of the words
  const usedLetters = useMemo(() => {
    return (
      words
        // Get the initial letter from every word (in upper case)
        .map((word) => word.word[0].toUpperCase())
        // Remove any duplicate letters
        .filter((letter, i, arr) => arr.indexOf(letter) !== i)
    );
  }, [words]);

  // Track whether the user has scrolled to toggle our "Back to top" button
  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    const onScroll = () => setHasScrolled(window.pageYOffset > 0);
    onScroll();

    const throttledScroll = throttle(onScroll, 100);
    window.addEventListener('scroll', throttledScroll);
    return () => window.removeEventListener('scroll', throttledScroll);
  }, []);

  // Override anchor link clicks to smoothly scroll to the anchor
  const onClickAnchorLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    // Cancel the default behaviour of the anchor link
    e.preventDefault();

    // Get the ID of the anchor element from the link href
    const id = e.currentTarget.getAttribute('href')?.slice(1);
    if (!id) return;

    // Get the anchor element from the ID
    const anchor = document.getElementById(id);
    if (!anchor) return;

    // Get the position of the anchor, smoothly scroll to it with the desired offset
    const anchorTop = anchor.getBoundingClientRect().top + window.pageYOffset;
    const scrollOffset = 64;
    window.scrollTo({ top: anchorTop - scrollOffset, behavior: 'smooth' });

    // Focus the anchor element for accessibility
    anchor.focus({ preventScroll: true });
    // Update the hash in the URL
    const newUrl = `${window.location.href.split('#')[0]}#${id}`;
    window.history.pushState({}, '', newUrl);
  };

  // Load polyfill for `position: sticky` on browsers that need it
  const menuEl = useRef<HTMLDivElement | undefined>(undefined);
  const stickyfill = useStickyfill();
  useEffect(() => {
    if (stickyfill === null || menuEl.current === undefined) {
      return undefined;
    }

    const { current } = menuEl;
    stickyfill.addOne(current);
    return () => {
      stickyfill.removeOne(current);
    };
  }, [stickyfill, menuEl.current]);

  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <PageHeader
            title={title}
            description={description}
            mobileImageUrl={illustrationMobileHeader}
            desktopImageUrl={illustrationDesktopHeader}
          />
        </FlexGridItem>
      </FlexGridRow>
      <FlexGridRow paddingY={{ base: 10, lg: 16 }}>
        <FlexGridItem colWidth={{ base: 4, md: 2, lg: 5 }}>
          <Flex
            ref={menuEl}
            alignSelf="flex-start"
            position={{ base: 'static', md: 'sticky' }}
            top={{
              md: 10,
              lg: `calc(${spacing[16]} + ${desktopHeaderHeight})`,
            }}
          >
            <Stack
              borderColor="lately.black40%"
              borderLeftStyle="solid"
              borderLeftWidth="2px"
              paddingLeft={3}
              spacing={2}
            >
              {chunk(alphabetLetters, lettersPerRow).map((row, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stack key={i} isInline spacing={0}>
                  {row.map((letter) => {
                    const isEnabled = usedLetters.includes(letter);
                    const Component = isEnabled ? Link : Text;
                    return (
                      <Component
                        key={letter}
                        href={isEnabled ? `#${letter}` : undefined}
                        onClick={isEnabled ? onClickAnchorLink : undefined}
                        {...textStyles.h5}
                        alignItems="center"
                        boxSizing="content-box"
                        color={
                          isEnabled ? 'lately.highlights03' : 'lately.black40%'
                        }
                        display="flex"
                        fontWeight="normal"
                        height={rem('28px')}
                        justifyContent="center"
                        padding={2}
                        textDecoration={isEnabled ? 'underline' : 'none'}
                        width={rem('28px')}
                        _hover={{ textDecoration: 'none' }}
                      >
                        {letter}
                      </Component>
                    );
                  })}
                </Stack>
              ))}
            </Stack>
          </Flex>
        </FlexGridItem>
        <FlexGridItem
          colWidth={{ base: 4, md: 2, lg: 7 }}
          marginTop={{ base: 16, md: 0 }}
        >
          <Stack spacing={10} width="100%">
            {sortedWords.map(({ word, definition }, i) => (
              <Stack
                key={word}
                spacing={1}
                width="100%"
                // TODO: Is there a better way to remove the margin from the last Markdown paragraph?
                css={css`
                  *:last-child {
                    margin-bottom: 0;
                  }
                `}
              >
                {/* If this is the first word starting with a certain letter, add an anchor so we can navigate here */}
                {isFirstWordWithInitialLetter(i, sortedWords) && (
                  <Flex
                    id={word[0].toUpperCase()}
                    position="relative"
                    tabIndex={-1}
                    // Offset the anchor position on desktop to account for the height of the sticky primary nav
                    top={{ base: 0, lg: `-${desktopHeaderHeight}` }}
                  />
                )}
                <Heading as="h2" {...textStyles.h5}>
                  {word}
                </Heading>
                <Markdown
                  options={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    createElement(type, props: any, text) {
                      const { key } = props;
                      return (
                        <MarkdownComponent
                          type={type as keyof ReactHTML}
                          props={props}
                          key={key}
                        >
                          {text}
                        </MarkdownComponent>
                      );
                    },
                  }}
                >
                  {definition}
                </Markdown>
              </Stack>
            ))}
          </Stack>
        </FlexGridItem>
      </FlexGridRow>
      <Flex
        bottom={0}
        justifyContent="center"
        left={0}
        pointerEvents="none"
        position="fixed"
        right={0}
      >
        <Flex
          justifyContent="flex-end"
          maxWidth="contentMax"
          paddingBottom={{ base: 4, md: 6, lg: 10 }}
          paddingX={gridConfig.margin}
          width="100%"
        >
          <Button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            borderRadius={rem('60px')}
            opacity={hasScrolled ? 1 : 0}
            pointerEvents={hasScrolled ? 'auto' : 'none'}
            transition="opacity 0.3s"
          >
            Back to top
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
});

export default Glossary;
