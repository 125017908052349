import React, { useEffect, useRef } from 'react';
import { rem, stripUnit } from 'polished';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/core';

import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import { ActionsSection } from '@/components/sections';

export type ConversationStartersContentMobileProps = {
  conversationStarters: {
    heading: string;
    description: string;
    questions: string[];
    slug: string;
  }[];
  selectedIndex: number | null;
  onChangeIndex: (index: number | null) => void;
  onShare: () => void;
};

const ConversationStartersContentMobile: React.FC<ConversationStartersContentMobileProps> = ({
  conversationStarters,
  selectedIndex,
  onChangeIndex,
  onShare,
}) => {
  // Scroll to an accordion button using the item index
  const accordionButtonEls = useRef<(HTMLButtonElement | undefined)[]>([]);
  const accordionContentEls = useRef<(HTMLDivElement | undefined)[]>([]);
  const scrollToAccordion = (index: number) => {
    // Get the button element using the given index
    const buttonEl = accordionButtonEls.current[index];
    if (!buttonEl) return;

    // Get the position of the button on the page
    const buttonRect = buttonEl.getBoundingClientRect();
    let scrollY = buttonRect.top + window.pageYOffset - 16;

    // If there's an open accordion above, subtract the height to account for it closing
    if (selectedIndex !== null && selectedIndex < index) {
      const selectedEl = accordionContentEls.current[selectedIndex];
      if (selectedEl) {
        scrollY -= selectedEl.getBoundingClientRect().height;
      }
    }

    // Smoothly scroll to the calculated position
    window.scrollTo({ top: scrollY, behavior: 'smooth' });
  };

  // When the user presses an accordion button, toggle it
  const onToggle = (index: number) => {
    const isOpening = index !== selectedIndex;
    onChangeIndex(isOpening ? index : null);
    // If the accordion is opening, scroll to the button
    if (isOpening) {
      scrollToAccordion(index);
    }
  };

  // When the user presses "Close", close the accordion and scroll back to the button
  const onClose = (index: number) => {
    onChangeIndex(null);
    scrollToAccordion(index);
  };

  // On page load, if an item is selected then scroll to it
  useEffect(() => {
    if (selectedIndex !== null) {
      scrollToAccordion(selectedIndex);
    }
  }, []);

  return (
    <Stack display={{ base: 'flex', md: 'none' }} paddingY={10} spacing={4}>
      <Stack alignItems="center" direction="row" spacing={6}>
        <Box flexGrow={1}>
          <Heading as="h2" opacity={0.8} {...textStyles.body}>
            Select a topic
          </Heading>
        </Box>
      </Stack>
      <Accordion allowMultiple={false} allowToggle>
        <Stack spacing={rem('10px')}>
          {conversationStarters.map((c, i) => (
            <AccordionItem
              key={c.slug}
              isOpen={i === selectedIndex}
              border="none"
            >
              {({ isExpanded }) => (
                <>
                  <AccordionHeader
                    ref={(el) => {
                      accordionButtonEls.current[i] = el;
                    }}
                    onClick={() => onToggle(i)}
                    backgroundColor={
                      isExpanded ? 'lately.core02' : 'lately.core0210%'
                    }
                    borderRadius="2px"
                    color={
                      isExpanded ? 'lately.background' : 'lately.highlights03'
                    }
                    padding={0}
                    _hover={{
                      backgroundColor: 'lately.core02',
                      color: 'lately.background',
                    }}
                  >
                    <Stack
                      direction="row"
                      paddingLeft={6}
                      paddingRight={2}
                      paddingY={rem('10px')}
                      spacing={2}
                      textAlign="left"
                      width="100%"
                      {...textStyles.body}
                    >
                      <Text flexGrow={1} fontWeight="bold">
                        {c.heading}
                      </Text>
                      <Flex
                        alignItems="center"
                        // Set the box height to equal the height of 1 line of text, so that
                        // the chevron icon aligns vertically with the first line of button text
                        height={`calc((${stripUnit(
                          textStyles.body.lineHeight
                        )} / ${stripUnit(textStyles.body.fontSize)}) * ${
                          textStyles.body.fontSize
                        })`}
                        paddingX={rem('6px')}
                      >
                        <Icon
                          name="accordionChevron"
                          transform={
                            isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'
                          }
                          transition="transform 0.2s"
                          width={rem('12px')}
                        />
                      </Flex>
                    </Stack>
                  </AccordionHeader>
                  <AccordionPanel
                    ref={(el) => {
                      accordionContentEls.current[i] = el;
                    }}
                    padding={4}
                    paddingTop={6}
                  >
                    <Text {...textStyles.body}>{c.description}</Text>
                    <Text marginTop={4} {...textStyles.h4}>
                      Try asking:
                    </Text>
                    <Stack
                      backgroundColor="lately.core"
                      borderRadius="2px"
                      marginTop={2}
                      padding={6}
                      spacing={6}
                    >
                      {c.questions.map((q) => (
                        <Text key={q} {...textStyles.body} fontStyle="italic">
                          {q}
                        </Text>
                      ))}
                    </Stack>
                    <Text marginTop={4} {...textStyles.body}>
                      <strong>Remember:</strong> These prompts are to give the
                      other person an opportunity to talk if they want to. If
                      they don&apos;t want to talk, that&apos;s okay. Let them
                      know you are there for them when they need you.
                    </Text>
                    <ActionsSection
                      actions={[
                        ActionsSection.Actions.Print,
                        {
                          ...ActionsSection.Actions.Share,
                          children: 'Share',
                          onClick: onShare,
                        },
                      ]}
                      stackDirection="row"
                      marginTop={6}
                      width="100%"
                    />
                    <Button
                      variant="unstyled"
                      onClick={() => onClose(i)}
                      display="flex"
                      marginLeft="auto"
                      marginTop={6}
                      paddingY={3}
                    >
                      <Text {...textStyles.h5}>Close</Text>
                      <Icon
                        name="accordionChevron"
                        marginLeft={rem('10px')}
                        marginRight={2}
                        width={rem('12px')}
                      />
                    </Button>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Stack>
      </Accordion>
    </Stack>
  );
};

export default ConversationStartersContentMobile;
