import React, { useEffect, useState } from 'react';
import { Stack, Box, Flex, Image, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import { useRouter } from 'next/router';

import { SectionsProps } from '@/utils/types';
import {
  PageHeader,
  SocialShare,
  BlogTileProps,
  Button,
} from '@/components/ui';
import { ActionsSection, BlogPostsSection } from '@/components/sections';
import {
  FlexGridItem,
  FlexGridRow,
  Page,
  DynamicSection,
} from '@/components/layout';
import type { PageProps } from '@/components/layout';
import type { ImageProps } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import Link from 'next/link';

export type BlogPostProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  title: string;
  description: string;
  heroImage?: ImageProps;
  sections: SectionsProps;
  categories: string[];
  recentBlogPosts: BlogTileProps[];
} & FlexProps;

const BlogPost: React.FC<BlogPostProps> = React.memo(function BlogPost({
  pageProps,
  title,
  description,
  heroImage,
  sections,
  categories,
  recentBlogPosts,
  ...rest
}) {
  const router = useRouter();
  const [currentPagePath, setCurrentPagePath] = useState('');

  // fire on load
  useEffect(() => {
    setCurrentPagePath(router.asPath);
  }, []);

  const socialLinks = {
    facebookLink: `https://www.facebook.com/share.php?u=${process.env.SITE_URL}${currentPagePath}`,
    twitterLink: `https://twitter.com/intent/tweet?text=Check%20out%20this%20Precious%20Time%20article:&url=${process.env.SITE_URL}${currentPagePath}`,
    emailLink: `mailto:?&subject=Take a look at this Precious Time article&body=I thought you might be interested in this article: ${process.env.SITE_URL}${currentPagePath}`,
  };

  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <PageHeader title={title} description={description} />
        </FlexGridItem>
      </FlexGridRow>
      {heroImage && (
        <FlexGridRow marginTop={16}>
          <FlexGridItem colWidth={{ base: 4, md: 3, lg: 6 }}>
            <Box width="100%">
              <Image src={heroImage.url} />
            </Box>
          </FlexGridItem>
        </FlexGridRow>
      )}
      <Stack paddingY={{ base: 16, lg: 20 }}>
        <DynamicSection
          sections={sections}
          display="block"
          spacing={{ base: 16, lg: 20 }}
        />
      </Stack>
      <Stack paddingBottom={16} spacing={8}>
        {categories.length > 0 && (
          <Flex color="lately.core02" alignItems="center" flexWrap="wrap">
            <Text {...textStyles.h5} marginRight={8}>
              Categories:
            </Text>
            {categories.map((category) => (
              <Link href={`/blog?filter=${category.replace(' ', '+')}`}>
                <Button
                  aria-label={`Filter blog page by the ${category} category`}
                  key={category}
                  variant="textLink"
                  marginRight={2}
                >
                  <Text
                    {...textStyles.smallData}
                    fontWeight="bold"
                    backgroundColor="lately.core0210%"
                    paddingX={2}
                    paddingY={1}
                    width="fit-content"
                  >
                    {category}
                  </Text>
                </Button>
              </Link>
            ))}
          </Flex>
        )}
        <SocialShare
          facebookLink={socialLinks.facebookLink}
          twitterLink={socialLinks.twitterLink}
          emailLink={socialLinks.emailLink}
        />
        <ActionsSection actions={[ActionsSection.Actions.Print]} />
      </Stack>
      {pageProps.blogPostRecentProps && (
        <BlogPostsSection
          marginBottom={12}
          blogPosts={recentBlogPosts}
          heading={pageProps.blogPostRecentProps.heading}
          button={pageProps.blogPostRecentProps.button}
        />
      )}
    </Page>
  );
});

export default BlogPost;
