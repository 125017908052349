// Get the current URL without the query string
const getCurrentUrl = () => {
  if (typeof window === 'undefined') return '';

  return [
    window.location.protocol,
    '//',
    window.location.host,
    window.location.pathname,
  ].join('');
};

export default getCurrentUrl;
