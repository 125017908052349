import React from 'react';
import { rem } from 'polished';
import { Box, Divider, Stack, useDisclosure } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import {
  ContactDetails,
  PageHeader,
  SearchResultsMapModal,
  ServiceCost,
  ServiceMiscDetails,
  ShareModal,
  TextBlock,
  VenueInformation,
  WhereAndWhen,
  useShare,
} from '@/components/ui';
import {
  ActionsSection,
  CategoriesProps,
  HubListSection,
  RelatedCategoriesSection,
} from '@/components/sections';
import getCurrentUrl from '@/utils/getCurrentUrl';
import { useSavedContent } from '@/components/providers';
import { SavedContentState, SavedContentType } from '@/utils/saved-content';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import type {
  ContactDetailsProps,
  ServiceCostProps,
  ServiceMiscDetailsProps,
  VenueInformationProps,
  WhereAndWhenProps,
} from '@/components/ui';
import type { HubsListProps } from '@/components/sections';
import type { PageProps } from '@/components/layout';

export type ServiceProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  serviceId: string;
  title: string;
  provider: string;
  description: string;
  contact: ContactDetailsProps;
  whereAndWhen: WhereAndWhenProps;
  venue?: Omit<VenueInformationProps, 'onViewMap'>;
  misc: ServiceMiscDetailsProps;
  cost: ServiceCostProps;
  hubsListHeading: string;
  hubs: HubsListProps;
  categories: CategoriesProps;
} & FlexProps;

const Service: React.FC<ServiceProps> = React.memo(function Service({
  pageProps,
  serviceId,
  title,
  provider,
  description,
  categories,
  contact,
  cost,
  misc,
  venue,
  whereAndWhen,
  hubsListHeading,
  hubs,
  ...rest
}) {
  const mapModal = useDisclosure(false);
  const hasVenue =
    venue !== undefined &&
    venue?.address !== undefined &&
    venue?.address.trim() !== '';
  // a maximum of 4 hubs should be shown
  const hubsToShow = hubs.slice(0, 4);

  // Get the title and URL of the page for users to share
  const textToShare = [title, getCurrentUrl()].join('\n');
  const { onShare, showShareModal, onCloseShareModal } = useShare(textToShare);

  // Get the saved state of the service
  const savedContent = useSavedContent();
  const [savedState, onToggleSave] = savedContent.getItemState(
    serviceId,
    SavedContentType.Service
  );

  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <PageHeader title={title} description={`Provider: ${provider}`} />
        </FlexGridItem>
      </FlexGridRow>
      <Stack paddingY={{ base: 10, lg: 16 }} spacing={{ base: 16, lg: 20 }}>
        <FlexGridRow ignoreMargins={{ base: 1, md: 0 }}>
          <FlexGridItem colWidth={{ base: 4, md: 2, lg: 5 }}>
            <Stack
              paddingLeft={{ base: 4, md: 0 }}
              paddingRight={{ base: 4, md: 8, lg: 0 }}
              paddingY={{ base: 0, md: 10 }}
              spacing={10}
              width="100%"
            >
              <TextBlock
                headingStyle="h2"
                heading="About this service"
                body={description}
                width="100%"
              />
              <ActionsSection
                actions={[
                  {
                    ...ActionsSection.Actions.Save,
                    children:
                      savedState === SavedContentState.None
                        ? 'Save for later'
                        : 'Remove',
                    isLoading: savedState === SavedContentState.Pending,
                    onClick: onToggleSave,
                  },
                  {
                    ...ActionsSection.Actions.Share,
                    children: 'Share this service',
                    onClick: onShare,
                  },
                ]}
                width="100%"
              />
            </Stack>
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 6 }}
            colOffset={{ base: 0, lg: 1 }}
            marginTop={{ base: 16, md: 0 }}
          >
            <ContactDetails
              paddingX={{ base: 4, lg: 12 }}
              paddingY={10}
              {...contact}
            />
          </FlexGridItem>
        </FlexGridRow>
        <FlexGridRow ignoreMargins={{ base: 1, md: 0 }}>
          <FlexGridItem colWidth={{ base: 4, md: 2, lg: 6 }}>
            <WhereAndWhen
              paddingLeft={{ base: 4, md: 0 }}
              paddingRight={{ base: 4, md: 0, lg: 12 }}
              paddingY={{ base: 0, md: 10 }}
              {...whereAndWhen}
            />
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 6 }}
            marginTop={{ base: 16, md: 0 }}
          >
            {hasVenue ? (
              <VenueInformation
                paddingX={{ base: 4, lg: 12 }}
                paddingY={10}
                onViewMap={mapModal.onOpen}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                {...venue!}
              />
            ) : (
              <ServiceMiscDetails
                paddingLeft={{ base: 4, md: 4, lg: 12 }}
                paddingRight={{ base: 4, md: 0 }}
                paddingY={{ base: 0, md: 10 }}
                {...misc}
              />
            )}
          </FlexGridItem>
        </FlexGridRow>
        <FlexGridRow ignoreMargins={{ base: 1, md: 0 }}>
          {hasVenue && (
            <FlexGridItem colWidth={{ base: 4, md: 2, lg: 6 }}>
              <ServiceMiscDetails
                paddingLeft={{ base: 4, md: 0 }}
                paddingRight={{ base: 4, md: 0, lg: 12 }}
                paddingY={{ base: 0, md: 10 }}
                {...misc}
              />
            </FlexGridItem>
          )}
          <FlexGridItem
            colWidth={{ base: 4, md: 2, lg: 6 }}
            marginTop={hasVenue ? { base: 16, md: 0 } : {}}
          >
            <ServiceCost
              paddingLeft={{ base: 4, md: 0, lg: hasVenue ? 12 : 0 }}
              paddingRight={{ base: 4, md: 0, lg: hasVenue ? 0 : 12 }}
              paddingY={{ base: 0, md: 10 }}
              {...cost}
            />
          </FlexGridItem>
        </FlexGridRow>
      </Stack>
      <Divider
        marginTop={0}
        height="2px"
        color="lately.black40%"
        marginBottom={{ base: 16, lg: 20 }}
      />
      {hubs.length > 0 && (
        <Box marginBottom={{ base: 16, md: rem('72px'), lg: 20 }}>
          <HubListSection heading={hubsListHeading} hubs={hubsToShow} />
        </Box>
      )}
      {categories.length > 0 && (
        <Box
          marginBottom={{
            base: 24,
            md: rem('136px'),
            lg: 32,
          }}
        >
          <RelatedCategoriesSection
            heading="Related categories"
            categories={categories}
          />
        </Box>
      )}
      <ActionsSection
        marginBottom={32}
        actions={[
          ActionsSection.Actions.Print,
          {
            ...ActionsSection.Actions.Save,
            children:
              savedState === SavedContentState.None
                ? 'Save for later'
                : 'Remove',
            isLoading: savedState === SavedContentState.Pending,
            onClick: onToggleSave,
          },
          {
            ...ActionsSection.Actions.Share,
            children: 'Share this service',
            onClick: onShare,
          },
        ]}
        width="100%"
      />
      {mapModal.isOpen && (
        <SearchResultsMapModal
          isOpen={mapModal.isOpen}
          onClose={mapModal.onClose}
          // Filter the search to only show this service
          pageSearchState={{ filters: `objectID:${serviceId}_service` }}
        />
      )}
      <ShareModal
        isOpen={showShareModal}
        onClose={onCloseShareModal}
        textToShare={textToShare}
      />
    </Page>
  );
});

export default Service;
