import React from 'react';
import NextLink from 'next/link';
import { rem, stripUnit } from 'polished';
import { Box, Flex, Icon, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import * as textStyles from '@/theme/textStyles';
import { Button, PageHeader } from '@/components/ui';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import type { PageProps } from '@/components/layout';

export type FaqListProps = {
  pageProps: PageProps;
  metaTitle?: string;
  metaDescription?: string;
  shareImage?: string;
  title: string;
  description: string;
  illustrationMobileHeader: string;
  illustrationDesktopHeader: string;
  faqs: {
    title: string;
    url: string;
  }[];
} & FlexProps;

const FaqList: React.FC<FaqListProps> = React.memo(function FaqList({
  pageProps,
  title,
  description,
  illustrationMobileHeader,
  illustrationDesktopHeader,
  faqs,
  children,
  ...rest
}) {
  return (
    <Page {...pageProps} {...rest}>
      <FlexGridRow ignoreMargins={{ base: 1, lg: 1 / 2 }}>
        <FlexGridItem>
          <PageHeader
            title={title}
            description={description}
            mobileImageUrl={illustrationMobileHeader}
            desktopImageUrl={illustrationDesktopHeader}
            backgroundColor="lately.core"
            color="lately.text"
            desktopBackgroundProps={{ backgroundSize: '80%' }}
          />
        </FlexGridItem>
      </FlexGridRow>
      <FlexGridRow>
        <FlexGridItem colWidth={{ base: 4, md: 3, lg: 9 }}>
          <Stack paddingY={{ base: 10, lg: 16 }} spacing={2} width="100%">
            {faqs.map((faq) => (
              <Box key={faq.url}>
                <NextLink href={faq.url} passHref>
                  <Button
                    as="a"
                    variant="unstyled"
                    {...textStyles.h5}
                    backgroundColor="lately.answers"
                    display="flex"
                    paddingX={6}
                    paddingY={5}
                    width="100%"
                    _hover={{ backgroundColor: 'lately.highlights02' }}
                  >
                    <Text
                      flexGrow={1}
                      fontWeight="bold"
                      marginRight={6}
                      whiteSpace="normal"
                    >
                      {faq.title}
                    </Text>
                    <Flex
                      alignItems="center"
                      alignSelf="flex-start"
                      // Set the box height to equal the height of 1 line of text, so that
                      // the chevron icon aligns vertically with the first line of button text
                      height={`calc((${stripUnit(
                        textStyles.h5.lineHeight
                      )} / ${stripUnit(textStyles.h5.fontSize)}) * ${
                        textStyles.h5.fontSize
                      })`}
                      paddingX={rem('6px')}
                    >
                      <Icon
                        name="accordionChevron"
                        transform="rotate(90deg)"
                        width={rem('12px')}
                      />
                    </Flex>
                  </Button>
                </NextLink>
              </Box>
            ))}
          </Stack>
        </FlexGridItem>
      </FlexGridRow>
    </Page>
  );
});

export default FaqList;
