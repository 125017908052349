import React, { useEffect, useRef } from 'react';
import { rem, stripUnit } from 'polished';
import { Box, Flex, Heading, Icon, Stack, Text } from '@chakra-ui/core';

import { Button } from '@/components/ui';
import * as textStyles from '@/theme/textStyles';
import useStickyfill from '@/utils/useStickyfill';
import { ActionsSection } from '@/components/sections';
import useViewportHeight from '@/utils/useViewportHeight';
import { FlexGridItem, FlexGridRow } from '@/components/layout';

const desktopHeaderHeight = rem('96px');

export type ConversationStartersContentDesktopProps = {
  conversationStarters: {
    heading: string;
    description: string;
    questions: string[];
    slug: string;
  }[];
  selectedIndex: number | null;
  onChangeIndex: (index: number) => void;
  onShare: () => void;
};

const ConversationStartersContentDesktop: React.FC<ConversationStartersContentDesktopProps> = ({
  conversationStarters,
  selectedIndex,
  onChangeIndex,
  onShare,
}) => {
  // Default to the first conversation starter if none is selected
  const selected = conversationStarters[selectedIndex ?? 0];

  // Use the viewport height to calculate the height of the sticky sidebar
  const viewportHeight = useViewportHeight({ limit: 'debounce', wait: 100 });

  // Load polyfill for `position: sticky` on browsers that need it
  const sidebarEl = useRef<HTMLDivElement | undefined>(undefined);
  const stickyfill = useStickyfill();
  useEffect(() => {
    if (stickyfill === null || sidebarEl.current === undefined) {
      return undefined;
    }

    const { current } = sidebarEl;
    stickyfill.addOne(current);
    return () => {
      stickyfill.removeOne(current);
    };
  }, [stickyfill, sidebarEl.current]);

  // When the user presses a conversation starter, select it
  const onOpen = (index: number) => {
    onChangeIndex(index);
    // Scroll back to the top of the page
    window.scrollTo({ top: 0 });
  };

  return (
    <FlexGridRow
      display={{ base: 'none', md: 'flex' }}
      paddingBottom={{ md: 24, lg: 32 }}
    >
      <FlexGridItem colWidth={{ base: 2, lg: 5 }}>
        <Flex
          ref={sidebarEl}
          height={{
            base: viewportHeight,
            lg: `calc(${viewportHeight} - ${desktopHeaderHeight})`,
          }}
          position="sticky"
          top={{ base: 0, lg: desktopHeaderHeight }}
        >
          <Flex
            overflowY="auto"
            marginY={6}
            paddingRight={6}
            paddingTop={{ base: 0, lg: 10 }}
          >
            <Stack spacing={0} width="100%">
              <Stack
                alignItems="center"
                direction="row"
                flexShrink={0}
                spacing={6}
              >
                <Flex flexGrow={1} flexDirection="column">
                  <Heading as="h2" opacity={0.8} {...textStyles.body}>
                    Select a topic
                  </Heading>
                </Flex>
              </Stack>
              <Stack
                flexShrink={0}
                marginTop={{ base: 4, lg: 6 }}
                spacing={2}
                width="100%"
              >
                {conversationStarters.map((topic, i) => {
                  const isActive = i === (selectedIndex ?? 0);
                  return (
                    <Button
                      key={topic.slug}
                      variant="unstyled"
                      onClick={() => onOpen(i)}
                      isActive={isActive}
                      alignItems="flex-start"
                      backgroundColor={
                        isActive ? 'lately.core02' : 'lately.background'
                      }
                      borderRadius="2px"
                      color={
                        isActive ? 'lately.highlights' : 'lately.highlights03'
                      }
                      display="flex"
                      flexShrink={0}
                      paddingLeft={6}
                      paddingRight={2}
                      paddingY={4}
                      whiteSpace="normal"
                      {...textStyles.body}
                      _hover={{
                        backgroundColor: 'lately.core0210%',
                        color: 'lately.highlights03',
                      }}
                    >
                      <Text flexGrow={1} fontWeight="bold" marginRight={2}>
                        {topic.heading}
                      </Text>
                      <Flex
                        alignItems="center"
                        // Set the box height to equal the height of 1 line of text, so that
                        // the chevron icon aligns vertically with the first line of button text
                        height={`calc((${stripUnit(
                          textStyles.body.lineHeight
                        )} / ${stripUnit(textStyles.body.fontSize)}) * ${
                          textStyles.body.fontSize
                        })`}
                        paddingX={rem('6px')}
                      >
                        <Icon
                          name="accordionChevron"
                          transform="rotate(90deg)"
                          width={rem('12px')}
                        />
                      </Flex>
                    </Button>
                  );
                })}
              </Stack>
            </Stack>
          </Flex>
        </Flex>
      </FlexGridItem>
      <FlexGridItem
        colOffset={{
          base: 0,
          lg: 1,
        }}
        colWidth={{ base: 2, lg: 6 }}
      >
        <Flex
          direction="column"
          paddingX={6}
          paddingTop={{ md: 10, lg: 16 }}
          width="100%"
        >
          <Heading as="h2" {...textStyles.h2}>
            {selected.heading}
          </Heading>
          <Text {...textStyles.body} marginTop={{ base: 4, lg: 6 }}>
            {selected.description}
          </Text>
          <Text marginTop={{ base: 4, lg: 6 }} {...textStyles.h4}>
            Try asking:
          </Text>
          <Stack
            backgroundColor="lately.core"
            borderRadius="2px"
            marginTop={4}
            padding={6}
            spacing={6}
          >
            {selected.questions.map((q) => (
              <Text key={q} {...textStyles.body} fontStyle="italic">
                {q}
              </Text>
            ))}
          </Stack>
          <Text marginTop={6} {...textStyles.body}>
            <strong>Remember:</strong> These prompts are to give the other
            person an opportunity to talk if they want to. If they don&apos;t
            want to talk, that&apos;s okay. Let them know you are there for them
            when they need you.
          </Text>
          <ActionsSection
            actions={[
              ActionsSection.Actions.Print,
              {
                ...ActionsSection.Actions.Share,
                children: 'Share',
                onClick: onShare,
              },
            ]}
            marginTop={6}
            width="100%"
          />
        </Flex>
      </FlexGridItem>
    </FlexGridRow>
  );
};

export default ConversationStartersContentDesktop;
