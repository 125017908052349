import React from 'react';
import { rem } from 'polished';
import NextLink from 'next/link';
import { Heading, Link, List, ListItem, Stack, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Page } from '@/components/layout';
import * as textStyles from '@/theme/textStyles';
import type { PageProps } from '@/components/layout';

export type Error404Props = {
  pageProps: PageProps;
  hubs: {
    title: string;
    url: string;
  }[];
} & FlexProps;

const Error404: React.FC<Error404Props> = React.memo(function Error404({
  pageProps,
  hubs,
  ...rest
}) {
  return (
    <Page {...pageProps} {...rest}>
      <Stack
        margin="auto"
        maxWidth={{ base: rem('343px'), md: rem('366px'), lg: rem('468px') }}
        paddingBottom={{ base: 24, lg: 32 }}
        paddingTop={{ base: 10, lg: 16 }}
        spacing={6}
        width="100%"
      >
        <Heading as="h1" {...textStyles.h3} marginBottom={4}>
          Sorry, we couldn’t find that page. Try searching, or browsing:
        </Heading>
        <List spacing={4} styleType="disc">
          {hubs.map((hub) => (
            <ListItem key={hub.url}>
              <NextLink href={hub.url} passHref>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  {...textStyles.body}
                  textDecoration="underline"
                  _hover={{ textDecoration: 'none' }}
                >
                  {hub.title}
                </Link>
              </NextLink>
            </ListItem>
          ))}
        </List>
        <Text opacity={0.8} {...textStyles.smallData}>
          Error code: 404
        </Text>
      </Stack>
    </Page>
  );
});

export default Error404;
