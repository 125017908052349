import React, { useCallback, useState, useRef, useEffect } from 'react';
import type { FlexProps } from '@chakra-ui/core';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/core';
import { NextRouter, useRouter } from 'next/router';
import { connectSearchBox, Configure } from 'react-instantsearch-dom';

import {
  urlToSearchState,
  searchStateToUrl,
  getAppliedRefinements,
} from '@/utils/search';
import type { SearchState } from '@/utils/search';

import { breakpoints } from '@/theme/chakra';
import { FlexGridItem, FlexGridRow, Page } from '@/components/layout';
import {
  CurrentRefinements,
  SearchResultsStats,
  FilterResults,
  FilterResultsDrawer,
  Pagination,
  SearchResultsHeader,
  SearchResultCardHits,
  SearchResultsMapModal,
  ServiceSearch,
} from '@/components/ui';
import type { PageProps } from '@/components/layout';
import * as textStyles from '@/theme/textStyles';
import getFilterState from '@/utils/getFilterState';
import useStickyfill from '@/utils/useStickyfill';

export type SearchResultsProps = {
  pageProps: PageProps;
  query?: string;
  isFiltered?: boolean;
  numberOfFilters?: number;
  onOpenMap?: () => void;
  onOpenFilterDrawer?: () => void;
  onChangeTitle?: (title: string) => void;
} & FlexProps;

const updateHistory = (router: NextRouter, nextState: SearchState) =>
  router.push(searchStateToUrl(router.pathname, nextState), undefined, {
    shallow: true,
  });

const VirtualSearchBox = connectSearchBox(() => null);

const SearchResults: React.FC<SearchResultsProps> = React.memo(
  function SearchResults({
    pageProps,
    query = '',
    isFiltered = false,
    numberOfFilters = 0,
    onOpenMap = () => {},
    onOpenFilterDrawer = () => {},
    ...rest
  }) {
    // Load polyfill for `position: sticky` on browsers that need it
    const stickyfill = useStickyfill();
    const stickyEl = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (stickyfill === null || stickyEl.current === null) {
        return undefined;
      }

      const { current } = stickyEl;
      const isLgBreakpoint = () =>
        window.matchMedia(`(min-width: ${breakpoints.lg})`).matches;
      const onResize = () => {
        if (isLgBreakpoint()) {
          stickyfill.addOne(current);
        } else {
          stickyfill.removeOne(current);
        }
      };
      onResize();

      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
        stickyfill.removeOne(current);
      };
    }, [stickyfill, stickyEl.current]);

    return (
      <Page {...pageProps} {...rest}>
        {/* Synchronise search query that is set in the SearchResultsHeader */}
        <VirtualSearchBox defaultRefinement={query} />
        <Box
          ref={stickyEl}
          position={{ base: 'sticky', lg: 'inherit' }}
          top={0}
          width="100%"
          zIndex={25}
        >
          <FlexGridRow
            ignoreMargins={{ base: 1, lg: 1 / 2 }}
            display="flex"
            position="relative"
            top={0}
          >
            <FlexGridItem colWidth={{ base: 4, lg: 12 }}>
              <SearchResultsHeader
                inputPlaceholder="Search for service, topics or location"
                imageDesktopUrl={require('@/mockData/images/illustration-search-results-header.svg')}
                onOpenFilters={onOpenFilterDrawer}
                onViewMap={onOpenMap}
                numberOfFilters={numberOfFilters}
                parentQuery={query}
              />
            </FlexGridItem>
          </FlexGridRow>
        </Box>
        <FlexGridRow
          display={{ base: 'none', lg: 'flex' }}
          height={isFiltered ? 'auto' : 10}
        >
          {isFiltered ? (
            <FlexGridItem
              colWidth={{ base: 0, lg: 12 }}
              paddingTop={4}
              paddingBottom={10}
              alignItems="center"
            >
              <Text
                {...textStyles.h5}
                color="lately.highlights03"
                marginRight={8}
                minWidth="140px"
              >
                Active filters:
              </Text>
              <CurrentRefinements />
            </FlexGridItem>
          ) : null}
        </FlexGridRow>

        <FlexGridRow>
          <FlexGridItem
            colWidth={{ base: 0, lg: 3 }}
            display={{ base: 'none', lg: 'flex' }}
          >
            <FilterResults />
          </FlexGridItem>
          <FlexGridItem
            colWidth={{ base: 4, lg: 8 }}
            colOffset={{ base: 0, lg: 1 }}
            flexDirection="column"
          >
            <SearchResultsStats query={query} onOpen={onOpenMap} />
            <Configure hitsPerPage={10} />
            <SearchResultCardHits {...pageProps.popularCategoriesProps} />
          </FlexGridItem>
        </FlexGridRow>
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginY={{ base: 16, md: 24, lg: 32 }}
        >
          <Pagination />
        </Flex>
      </Page>
    );
  }
);

// Extracts only the state we need out of the context to prevent unnecessary re-renders of the main component
const ConnectedSearchResults: React.FC<SearchResultsProps> = React.memo(
  function ConnectedSearchResults({ onChangeTitle, ...rest }) {
    const mapModal = useDisclosure();
    const filterDrawer = useDisclosure();
    const router = useRouter();
    const asPath = router?.asPath ?? '';

    // Track the "page" search state to pass it in to the map modal
    const [currentSearchState, setSearchState] = useState<SearchState>({});
    const onSearchStateChange = useCallback((searchState: SearchState) => {
      updateHistory(router, searchState);
    }, []);

    // Synchronise search state stored in the URL with the local state
    useEffect(() => {
      const nextSearchState = urlToSearchState(asPath);
      if (
        JSON.stringify(currentSearchState) !== JSON.stringify(nextSearchState)
      ) {
        setSearchState(nextSearchState);
      }
    }, [asPath]);

    // Update the page title based on the search state
    useEffect(() => {
      if (onChangeTitle === undefined) return;
      const { refinementList, query } = currentSearchState;
      const filters = getAppliedRefinements(refinementList ?? {});
      if (filters.length === 1) {
        onChangeTitle(`Search - ${filters[0]}`);
      } else if (query) {
        onChangeTitle(`Search - ${query}`);
      } else {
        onChangeTitle('Search');
      }
    }, [currentSearchState]);

    // Check if the results are filtered
    const { isFiltered, numberOfFilters } = getFilterState(currentSearchState);

    return (
      <ServiceSearch
        onSearchStateChange={onSearchStateChange}
        searchState={currentSearchState}
      >
        <SearchResults
          {...rest}
          query={currentSearchState.query}
          isFiltered={isFiltered}
          numberOfFilters={numberOfFilters}
          onOpenMap={mapModal.onOpen}
          onOpenFilterDrawer={filterDrawer.onOpen}
        />
        <FilterResultsDrawer
          isOpen={filterDrawer.isOpen}
          onClose={filterDrawer.onToggle}
          updatePageSearchState={onSearchStateChange}
          pageSearchState={currentSearchState}
        />
        <SearchResultsMapModal
          isOpen={mapModal.isOpen}
          onClose={mapModal.onClose}
          pageSearchState={currentSearchState}
        />
      </ServiceSearch>
    );
  }
);

export default ConnectedSearchResults;
